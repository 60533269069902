import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Follow from '../components/Follow'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, headings } = markdownRemark
  console.log(headings)
  return (
    <article>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={frontmatter.description} />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:site" content="@sanjeevan84" />
        <meta name="twitter:creator" content="@sanjeevan84" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.description} />
        <meta
          property="og:url"
          content={'https://sanjeevan.co.uk' + frontmatter.path}
        />
        <meta property="og:site_name" content="Sanj Ambalavanar" />
        <meta
          property="og:image"
          content={
            'https://sanjeevan.co.uk' +
            frontmatter.thumbnail.childImageSharp.fixed.src
          }
        />
      </Helmet>
      <div className="blog-post">
        <div className="blog-header">
          <div class="home-link">
            <Link to="/">More posts</Link>
          </div>
          <h1 className="title">{frontmatter.title}</h1>
          <div className="publishedAt">
            <span>Published:</span> {frontmatter.date}
          </div>
        </div>
        {headings.length > 0 && (
          <div className="toc">
            <div className="toc-title">Table of contents</div>
            <ul>
              {headings
                .filter((heading) => heading.depth === 2)
                .map((heading) => {
                  return (
                    <li>
                      <a href={`#${heading.id}`}>{heading.value}</a>
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <Follow />
    </article>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        thumbnail {
          childImageSharp {
            fixed(width: 400) {
              src
              srcSet
            }
          }
        }
      }
      headings {
        depth
        value
        id
      }
    }
  }
`
