import React from 'react'
import Pic from '../../assets/me.png'

const Follow = () => (
  <div className="follow-me">
    <div>
      <img alt="follow me" src={Pic} />
    </div>
    <div>
      <b>Sanj Ambalavanar</b> lives in London, where he works as a freelance
      software engineer. You should{' '}
      <a rel="noreferrer" href="https://twitter.com/sanjeevan84">
        follow him on twitter
      </a>
      .
    </div>
  </div>
)

export default Follow
